/* If you need to add @import statements, do so up here */
@import 'floating-logos.css';

@import 'jit-refresh.css'; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --nav-height: 6.25rem;
  --logo-height: 3rem;
  --content-padding-x: 1.25rem;
  --content-padding-x-lg: 2rem;
  --content-padding-y: 5rem;
  --content-padding-y-lg: 6.25rem;
  --content-max-width: theme('maxWidth.screen-xl');
}

@layer base {
  /* Default to `display: block` for images, SVGs, and custom tags */
  img, svg, text-block { @apply block }
  svg { @apply max-w-full }
  h1, h2, h3, h4, h5, h6 { @apply font-bold }
  h1, .h1 { @apply text-6xl sm:text-7xl md:text-8xl }
  h2, .h2 { @apply text-5xl md:text-6xl }
  h3, .h3 { @apply text-3xl md:text-4xl }
  h4, .h4 { @apply text-2xl md:text-3xl }
  p { @apply text-gray-600 leading-relaxed }

  .text-link, p > a {
    @apply text-green-500 hover:text-green-600 hover:underline duration-100
  }
}

@layer components {
  .input {
    @apply
      bg-transparent
      block
      border-none
      disabled:cursor-not-allowed
      disabled:hover:ring-gray-200
      disabled:opacity-50
      duration-100
      focus-visible:outline-none
      focus-visible:ring-2
      focus-visible:ring-gray-700
      focus-visible:ring-inset
      focus:outline-none
      focus:ring-2
      focus:ring-gray-700
      focus:ring-inset
      hover:ring-gray-300
      hover:ring-inset
      min-h-[3em]
      outline-none
      placeholder:text-gray-300
      px-[1em]
      py-[0.5em]
      ring-2
      ring-gray-200
      ring-inset
      rounded-md
      text-gray-800
      text-lg
      transition-shadow
      w-full;
  }

  /* Min/max width screen variants break if custom screen variant contains an object (`'no-hover': { 'raw': '(hover: none)' }`) so this is a workaround */
  @media (hover: none) {
    .job-opening icon:last-child { @apply translate-x-0 opacity-100 }
  }
}

@layer utilities {
  .center-absolute {
    @apply left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
  }

  .has-divider {
    @apply relative before:bg-green-500 mb-[1.5em] before:absolute before:left-0 before:bottom-[-0.5em] before:h-[calc(1em/9)] before:w-[1em];
  }

  .scrolling-underline {
    @apply relative before:absolute before:bottom-[-0.25em] before:text-inherit before:bg-current before:duration-250 before:h-[0.125em] before:right-0 before:w-0 hover:before:left-0 hover:before:w-full;
  }
}
